import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router';
import { RoleCode } from '../@types/User';
import { PATH_PAGE } from '../routes/paths';
import { findPermission } from '../sections/@dashboard/Permissions/utils';
import { useAuthContext } from './useAuthContext';
export default function PermissionGuard(_a) {
    var children = _a.children, model = _a.model, method = _a.method, _b = _a.onePermission, onePermission = _b === void 0 ? null : _b;
    var user = useAuthContext().user;
    var isSuperAdmin = (user === null || user === void 0 ? void 0 : user.role) === RoleCode.SUPER_ADMIN;
    if (isSuperAdmin)
        return _jsxs(_Fragment, { children: [" ", children, " "] });
    var isAllowedToAccessThisPage = !onePermission
        ? findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, model, method)
        : onePermission
            .map(function (elt) { return findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, elt[0], elt[1]); })
            .some(function (condition) { return condition; });
    if (isAllowedToAccessThisPage)
        return _jsx(_Fragment, { children: children });
    return _jsx(Navigate, { to: PATH_PAGE.page403 });
}
